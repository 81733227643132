@charset "UTF-8";
body {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif; }
  @media screen and (min-width: 62.0625em) {
    body {
      padding-top: 0; } }

h1,
h2,
h3 {
  font-weight: normal;
  font-family: "Shuei Mincho M", "游明朝", YuMincho, "Hiragino Mincho ProN", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
  letter-spacing: 0.1em; }

address {
  font-style: normal; }

img {
  border: 0; }

a {
  color: #2E3739; }

a:hover {
  color: #2E3739; }

a img {
  border: none; }

/* #header
------------------------------- */
nav.navbar {
  background: #fff;
  font-size: 1rem;
  padding: 0 1rem; }
  nav.navbar .container {
    position: relative; }
    @media screen and (min-width: 62.0625em) {
      nav.navbar .container {
        align-items: flex-end; } }
  nav.navbar a {
    text-decoration: none; }
    nav.navbar a.navbar-brand {
      padding: 12px 0; }
      @media screen and (max-width: 74.9375em) {
        nav.navbar a.navbar-brand img {
          width: 112px;
          padding: 4px 0 5px; } }
  nav.navbar .collapse {
    justify-content: flex-end;
    align-items: flex-end; }
    nav.navbar .collapse ul li {
      margin-bottom: 0; }
    @media screen and (min-width: 62.0625em) {
      nav.navbar .collapse ul {
        padding-bottom: 3px; }
        nav.navbar .collapse ul li a.nav-link {
          padding-left: .6rem;
          padding-right: .6rem; }
          nav.navbar .collapse ul li a.nav-link i {
            display: none; }
        nav.navbar .collapse ul li .dropdown-menu {
          left: auto; } }
    @media screen and (max-width: 62em) {
      nav.navbar .collapse ul {
        background: #fff;
        text-align: center;
        max-width: 720px;
        margin: 0 auto 10px; }
        nav.navbar .collapse ul li + li {
          border-top: 1px solid rgba(255, 255, 255, 0.4);
          padding: .2rem;
          border-radius: 0; }
        nav.navbar .collapse ul li a:hover {
          background: rgba(255, 255, 255, 0.1); } }
  nav.navbar #navbar-sp-menu {
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    align-items: flex-start; }
    nav.navbar #navbar-sp-menu .nav-title {
      font-size: 10px !important;
      font-weight: bold; }
      nav.navbar #navbar-sp-menu .nav-title + i {
        font-size: 26px;
        display: block; }
    nav.navbar #navbar-sp-menu a {
      text-align: center;
      padding: 0;
      color: #2E3739;
      width: 45px; }
    nav.navbar #navbar-sp-menu .navbar-toggler {
      width: 45px;
      padding: 0;
      text-align: center;
      line-height: 1.5;
      font-size: 1rem; }
      nav.navbar #navbar-sp-menu .navbar-toggler .nav-title {
        font-weight: normal;
        font-size: 9px; }
      nav.navbar #navbar-sp-menu .navbar-toggler i {
        font-size: 28px; }

.nav-btn {
  display: flex;
  height: 74px;
  justify-content: center;
  align-items: center; }
  .nav-btn a {
    color: #fff;
    display: inline-block;
    padding: 15px;
    text-align: center;
    font-size: 1.5rem;
    background: url("/_assets/img/nav-btn-bg.png") no-repeat bottom right; }
    .nav-btn a:hover {
      text-decoration: none; }
    .nav-btn a.nav-contact {
      color: #2E3739;
      font-size: 1rem;
      width: 9rem; }
  .nav-btn .nav-buying {
    background: #C62F2F;
    border: 4px solid #C62F2F; }
    .nav-btn .nav-buying:hover {
      background: #fff; }
      .nav-btn .nav-buying:hover a {
        color: #C62F2F;
        background-image: none; }
  .nav-btn .nav-biz {
    background: #5B8D26;
    border: 4px solid #5B8D26; }
    .nav-btn .nav-biz:hover {
      background: #fff; }
      .nav-btn .nav-biz:hover a {
        color: #5B8D26;
        background-image: none; }
  @media screen and (min-width: 75em) {
    .nav-btn {
      position: absolute;
      top: 0;
      right: 0; }
      .nav-btn a {
        width: 200px; } }
  @media screen and (max-width: 74.9375em) {
    .nav-btn {
      width: 100%;
      left: 0; }
      .nav-btn > div {
        width: 50%; }
        .nav-btn > div a {
          width: 100%; } }

/* #footer
------------------------------- */
#footer #sns {
  border-bottom: 1px dashed #707070; }
  #footer #sns p {
    margin-top: 1rem; }
    #footer #sns p a {
      text-decoration: none; }
      #footer #sns p a:hover {
        text-decoration: none;
        color: #707070; }
    #footer #sns p i {
      font-size: 27px; }
    #footer #sns p span {
      font-size: 23px; }

#footer .foot-nav {
  background: #2E3739;
  color: #FAF8DF;
  padding: 30px 0; }
  #footer .foot-nav .nav {
    margin-bottom: 20px;
    display: block; }
    #footer .foot-nav .nav li {
      color: #FAF8DF; }
      #footer .foot-nav .nav li a {
        color: #FAF8DF; }
    #footer .foot-nav .nav li {
      font-size: 1rem;
      margin-bottom: .2rem; }
      #footer .foot-nav .nav li a {
        padding: 0; }
      #footer .foot-nav .nav li ul {
        padding-left: 1.1rem;
        margin-top: .15rem; }
  #footer .foot-nav .contact {
    color: #FAF8DF; }
    #footer .foot-nav .contact .btn {
      border: 1px solid #FAF8DF;
      color: #FAF8DF;
      font-size: 1.3rem;
      display: block;
      padding: .2rem; }
      #footer .foot-nav .contact .btn i {
        padding-right: .3rem; }
      #footer .foot-nav .contact .btn:hover {
        background: #FAF8DF;
        color: #2E3739; }
      #footer .foot-nav .contact .btn img {
        width: 18px;
        opacity: .9;
        vertical-align: baseline; }
    #footer .foot-nav .contact p {
      font-size: 1.05rem;
      margin-bottom: .5rem; }
    #footer .foot-nav .contact .note {
      font-size: .9rem;
      padding-top: .3rem;
      margin-bottom: 1rem; }
  #footer .foot-nav .calendar h2 {
    font-size: 1.4rem;
    color: #FAF8DF;
    font-family: "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif; }
  #footer .foot-nav .calendar .cal {
    background: #FAF8DF;
    color: #2E3739;
    padding: 10px;
    margin-bottom: 15px; }
    @media screen and (min-width: 62.0625em) {
      #footer .foot-nav .calendar .cal {
        padding: 5px 10px 10px; } }
    #footer .foot-nav .calendar .cal table {
      width: 100%; }
      #footer .foot-nav .calendar .cal table caption {
        caption-side: top;
        text-align: center;
        padding: 5px 0;
        color: #2E3739;
        font-size: .95rem;
        font-weight: bold; }
      @media screen and (min-width: 62.0625em) {
        #footer .foot-nav .calendar .cal table {
          font-size: 14px; } }
      #footer .foot-nav .calendar .cal table .closed {
        color: #c00; }

#footer .foot-info {
  background: #fff;
  color: #2E3739; }
  #footer .foot-info .contact {
    margin: 25px 0 12px;
    line-height: 1.65; }
    #footer .foot-info .contact dt {
      font-size: 1.1rem; }
    #footer .foot-info .contact dd {
      margin-bottom: 0;
      font-size: .95rem; }
    @media screen and (min-width: 48.0625em) {
      #footer .foot-info .contact dt, #footer .foot-info .contact dd {
        display: inline-block;
        margin-right: .6rem; } }
  #footer .foot-info .copyright {
    font-size: 11px;
    padding: 10px 0 40px; }
  #footer .foot-info #page-top {
    display: none;
    position: fixed;
    right: 20px;
    bottom: 20px; }
  #footer .foot-info #page-top a {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 10px;
    text-align: center;
    padding: 2px 8px;
    text-decoration: none;
    display: block;
    background: #fff;
    background: rgba(255, 255, 255, 0.9);
    -webkit-border-radius: 40px;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); }
  #footer .foot-info p.copyright {
    margin: 1em 1em 1em 0;
    font-size: 70%;
    color: #666; }

.gototop {
  z-index: 110;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  background: #000;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  display: none; }
  .gototop a {
    text-decoration: none;
    display: block;
    text-align: center;
    color: #fff;
    padding: 8px;
    font-size: 10px;
    line-height: 1; }
    .gototop a i {
      font-size: 28px;
      display: block;
      padding-top: 3px; }

/* #content
------------------------------- */
.contents {
  background: #fff94a url("/_assets/img/bg.png");
  padding-bottom: 25px; }
  .contents #headline {
    background: #2E3739;
    color: #fff;
    margin-bottom: 15px; }
    .contents #headline h1 a {
      color: #fff; }
      .contents #headline h1 a:hover {
        text-decoration: none; }
    @media screen and (min-width: 62.0625em) {
      .contents #headline h1 {
        font-size: 1.8rem; } }
    @media screen and (max-width: 62em) {
      .contents #headline h1 {
        font-size: 1.5rem;
        margin: 0; } }
  .contents h2 {
    line-height: 1.15;
    margin-bottom: .9rem;
    font-size: 1.75rem; }
  .contents h3 {
    background: #2E3739;
    color: #fff;
    text-align: center;
    padding: 10px;
    font-size: 1.25rem;
    margin-bottom: .7rem; }
  .contents p.note {
    color: #353E3E;
    font-size: .9rem; }
  .contents .block + .block {
    margin-top: 3rem; }
  .contents .viewmore {
    padding: 1rem 0; }
    .contents .viewmore a {
      display: inline-block;
      border: 2px dashed #2E3739;
      padding: 9px 0 11px;
      width: 280px; }
      .contents .viewmore a:hover {
        text-decoration: none;
        background: #2E3739;
        color: #fff; }
  .contents .table {
    border-bottom: 1px solid #2E3739; }
    .contents .table td, .contents .table th {
      border-top-color: #2E3739; }
  .contents .kerning {
    font-feature-settings: "palt"; }

/* top
------------------------------- */
.top #feature #feature-inner {
  background-image: url("/uploads/feature-5.png"), url("/uploads/feature-4.png"), url("/uploads/feature-3.png"), url("/uploads/feature-2.png"), url("/uploads/feature-1.png");
  background-repeat: no-repeat, no-repeat, repeat-x, no-repeat, repeat-x;
  background-position: top center;
  background-size: cover; }
  @media screen and (max-width: 62em) {
    .top #feature #feature-inner {
      background-position: top 10%, top center, top center, top center, top center; } }
  @media screen and (min-width: 75em) {
    .top #feature #feature-inner {
      background-size: contain; } }

.top .contents #news .slider {
  overflow: hidden; }

.top .contents #news .slide {
  margin: 8px;
  cursor: pointer; }
  .top .contents #news .slide .news-img {
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    padding-bottom: 75%; }

.top .contents #news h3 {
  background: transparent;
  color: #2E3739;
  font-size: 1rem;
  font-weight: normal !important;
  text-align: left;
  padding: 0;
  margin: .4rem 0 .2rem; }
  .top .contents #news h3 a {
    color: #2E3739; }

.top .contents #news div.entry-date {
  font-size: .8rem;
  color: rgba(0, 0, 0, 0.45); }

.top .contents #movie .movie {
  position: relative;
  padding: 0 0 60.2%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .top .contents #movie .movie iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/* buying
------------------------------- */
.buying #headline {
  background: #C62F2F; }

.buying .contents .tel {
  font-size: 2.6rem;
  text-align: center;
  font-weight: bold; }
  .buying .contents .tel a {
    text-decoration: none; }

.buying .contents #flow, .buying .contents #voices, .buying .contents #staff {
  border-top: 1px dashed #353E3E;
  padding-top: 2rem;
  margin-top: 2rem; }

.buying .contents #kanri .points h3 {
  background: #C62F2F;
  font-size: 1.8rem;
  padding: 3px; }
  .buying .contents #kanri .points h3 strong {
    font-size: 3rem;
    vertical-align: middle;
    padding-right: .3rem; }
  .buying .contents #kanri .points h3 span {
    vertical-align: middle; }

.buying .contents #kanri p.lead span {
  border-bottom: 1px dashed #353E3E;
  padding-bottom: .3rem;
  font-size: 1.6rem; }

.buying .contents #kanri p.lead + h2 {
  font-size: 3rem; }

.buying .contents #flow .flow-down {
  font-size: 3rem; }

.buying .contents #flow p {
  margin-bottom: 0; }

.buying .contents #flow .link a {
  display: block;
  max-width: 270px;
  margin: 0 auto 10px; }

.buying .contents #voices {
  border-top: 1px dashed #353E3E;
  padding-top: 2rem;
  margin-top: 2rem; }
  .buying .contents #voices .voice h3 {
    background: transparent;
    color: #2E3739; }

.buying .contents #staff img {
  border-radius: 50%;
  display: block;
  margin-bottom: 5px; }

/* biz
------------------------------- */
.biz #headline {
  background: #5B8D26; }

.biz .contents .tel {
  font-size: 2.6rem;
  text-align: center;
  font-weight: bold; }
  .biz .contents .tel a {
    text-decoration: none; }

.biz .contents #comparison, .biz .contents #voices, .biz .contents #staff, .biz .contents #form {
  border-top: 1px dashed #353E3E;
  padding-top: 2rem;
  margin-top: 2rem; }

.biz .contents #kanri p.lead span {
  border-bottom: 1px dashed #353E3E;
  padding-bottom: .3rem;
  font-size: 1.6rem; }

.biz .contents #kanri p.lead + h2 {
  font-size: 2.8rem; }

.biz .contents #kanri .points p strong {
  font-size: 1.4rem;
  display: block; }

.biz .contents #comparison em {
  color: #C62F2F;
  font-style: normal; }

.biz .contents #staff .row {
  flex-wrap: wrap; }

.biz .contents #staff img {
  border-radius: 50%;
  display: block;
  margin-bottom: 5px; }

/* about
------------------------------- */
.about-index .contents p {
  font-family: "Shuei Mincho M", "游明朝", YuMincho, "Hiragino Mincho ProN", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
  font-size: 18px;
  line-height: 2; }

.about-index .contents .message h2 {
  line-height: 1.2; }
  @media screen and (min-width: 62.0625em) {
    .about-index .contents .message h2 {
      width: 1rem; } }

.about-index .contents .message img.sho {
  width: 280px; }

.about-index .contents .message img.photo {
  width: 275px; }

.about-index .contents #mission li {
  font-family: "Shuei Mincho M", "游明朝", YuMincho, "Hiragino Mincho ProN", "HGS明朝E", "ＭＳ Ｐ明朝", serif; }

.about-index .contents #philosophy p, .about-index .contents #mission li {
  font-size: 16px; }

.about-index .contents #mission ul {
  padding-left: 1.4rem; }

.about-index .contents #mission li {
  margin-bottom: .5rem; }

.about-activity p a {
  text-decoration: underline; }

.about-process .flow-label h2 {
  color: #fff;
  font-size: 1.1rem;
  padding: .4rem .6rem; }
  .about-process .flow-label h2.block-h-1 {
    background: #00244F; }
  .about-process .flow-label h2.block-h-2 {
    background: #003B79; }
  .about-process .flow-label h2.block-h-3 {
    background: #0058A6; }

/* news
------------------------------- */
.news .contents img {
  max-width: 100% !important;
  height: auto !important; }

.news-list .contents .asset {
  cursor: pointer; }
  .news-list .contents .asset h2, .news-list .contents .asset h3 {
    background: transparent;
    color: #2E3739;
    font-size: 1rem !important;
    font-weight: normal !important;
    text-align: left;
    padding: 0;
    margin: .4rem 0 .2rem; }
    .news-list .contents .asset h2 a, .news-list .contents .asset h3 a {
      color: #2E3739; }
  .news-list .contents .asset div.entry-date {
    font-size: .8rem;
    color: rgba(0, 0, 0, 0.45); }

.news-detail .contents .asset .entry-date {
  font-size: .85rem;
  color: #777; }
  .news-detail .contents .asset .entry-date a {
    color: #777;
    text-decoration: none; }

/* recruit
------------------------------- */
.recruit .nowrap {
  white-space: nowrap; }
