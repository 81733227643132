@import "mixin";

$offcanvas-width: 250px !default;
$color-base: #2E3739;

@charset "UTF-8";
body{
  @include mq('min', 'lg') {
    padding-top: 0;
  }
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
}
h1,
h2,
h3 {
  font-weight: normal;
  font-family: "Shuei Mincho M", "游明朝", YuMincho, "Hiragino Mincho ProN", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
  letter-spacing: 0.1em;
}
address{font-style:normal;}
img{border:0;}
a{color:$color-base;}
a:hover{color:$color-base;}
a img{border:none;}



/* #header
------------------------------- */
nav.navbar{
  background: #fff;
  font-size: 1rem;
  padding: 0 1rem;
  .container{
    position: relative;
    @include mq('min', 'lg') {
      align-items: flex-end;
    }
  }
  a{
    text-decoration:none;
    &.navbar-brand{
      padding:12px 0;
      @include mq('max', 'xl') {
        img{
          width: 112px;
          padding:4px 0 5px;
        }
      }
    }
  }
  .collapse{
    justify-content: flex-end;
    align-items: flex-end;
    ul{
      li{
        margin-bottom: 0;
      }
      @include mq('min', 'lg') {
        padding-bottom: 3px;
        li{
          a.nav-link{
            padding-left: .6rem;
            padding-right: .6rem;
            i{
              display: none;
            }
          }
          .dropdown-menu{
            left:auto;
          }
        }
      }
      @include mq('max', 'lg') {
        background: #fff;
        text-align: center;
        max-width: 720px;
        margin:0 auto 10px;
        li{
          &+li{
            border-top: 1px solid rgba(255,255,255,.4);
            padding: .2rem;
            border-radius: 0;
          }
          a{
            &:hover{
              background: rgba(255,255,255,.1);
            }
          }
        }
      }
    }
  }
  #navbar-sp-menu{
    position: absolute;
    top:10px;
    right: 0;
    display: flex;
    align-items: flex-start;
    .nav-title{
      font-size:10px !important;
      font-weight: bold;
      &+ i{
        font-size: 26px;
        display:block;
      }
    }
    a{
      text-align:center;
      padding:0;
      color: $color-base;
      width: 45px;
    }
    .navbar-toggler{
      width: 45px;
      padding:0;
      text-align:center;
      line-height: 1.5;
      font-size: 1rem;
      .nav-title{
        font-weight: normal;
        font-size: 9px;
      }
      i{
        font-size: 28px;
      }
    }
  }
}
.nav-btn{
  display: flex;
  height: 74px;
  justify-content: center;
  align-items: center;
  a{
    color: #fff;
    display: inline-block;
    padding: 15px;
    text-align: center;
    font-size: 1.5rem;
    background: url('/_assets/img/nav-btn-bg.png') no-repeat bottom right;
    &:hover{
      text-decoration: none;
    }
    &.nav-contact{
      color: $color-base;
      font-size: 1rem;
      width: 9rem;
    }
  }
  .nav-buying{
    background: #C62F2F;
    border:4px solid #C62F2F;
    &:hover{
      background: #fff;
      a{
        color: #C62F2F;
        background-image: none;
      }
    }
  }
  .nav-biz{
    background: #5B8D26;
    border:4px solid #5B8D26;
    &:hover{
      background: #fff;
      a{
        color: #5B8D26;
        background-image: none;
      }
    }
  }
  @include mq('min', 'xl') {
    position: absolute;
    top:0;
    right:0;
    a{
      width: 200px;
    }
  }
  @include mq('max', 'xl') {
    width: 100%;
    left: 0;
    &>div{
      width: 50%;
      a{
        width: 100%;
      }
    }
  }
}


/* #footer
------------------------------- */
#footer{
  #sns{
    border-bottom: 1px dashed #707070;
    p{
      margin-top: 1rem;
      a{
        text-decoration: none;
        &:hover{
          text-decoration: none;
          color: #707070;
        }
      }
      i{
        font-size: 27px;
      }
      span{
        font-size: 23px;
      }
    }
  }
  .foot-nav{
    background: $color-base;
    color: #FAF8DF;
    padding: 30px 0;
    .nav{
      li {
        color: #FAF8DF;
        a {
          color: #FAF8DF;
        }
      }
      margin-bottom: 20px;
      display: block;
      li{
        font-size: 1rem;
        margin-bottom: .2rem;
        a{
          padding:0;
        }
        ul{
          padding-left: 1.1rem;
          margin-top: .15rem;
        }
      }
    }
    .contact{
      color: #FAF8DF;
      .btn{
        border:1px solid #FAF8DF;
        color: #FAF8DF;
        font-size: 1.3rem;
        display: block;
        padding: .2rem;
        i{
          padding-right: .3rem;
        }
        &:hover{
          background: #FAF8DF;
          color: $color-base;
        }
        img{
          width: 18px;
          opacity: .9;
          vertical-align: baseline;
          // background: url('/uploads/icon-line.svg') no-repeat / 18px auto;
          // &:hover{
          //   opacity: 0;
          // }
        }
      }
      p{
        font-size: 1.05rem;
        margin-bottom: .5rem;
      }
      .note{
        font-size: .9rem;
        padding-top: .3rem;
        margin-bottom: 1rem;
      }
    }
    .calendar{
      h2{
        font-size: 1.4rem;
        color: #FAF8DF;
        font-family: "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
      }
      .cal{
        background: #FAF8DF;
        color: $color-base;
        padding: 10px;
        margin-bottom: 15px;
        @include mq('min', 'lg') {
          padding:5px 10px 10px;
        }
        table{
          width: 100%;
          caption{
            caption-side: top;
            text-align: center;
            padding:5px 0 ;
            color: $color-base;
            font-size: .95rem;
            font-weight: bold;
          }
          @include mq('min', 'lg') {
            font-size: 14px;
          }
          .closed{
            color: #c00;
          }
        }
      }
    }
  }
  .foot-info{
    background: #fff;
    color: #2E3739;
    .contact {
      margin: 25px 0 12px;;
      line-height: 1.65;
      dt{
        font-size: 1.1rem;
      }
      dd{
        margin-bottom: 0;
        font-size: .95rem;
      }
      @include mq('min', 'md') {
        dt,dd{
          display: inline-block;
          margin-right: .6rem;
        }
      }
    }
    .copyright {
      font-size: 11px;
      padding: 10px 0 40px;
    }
    #page-top {
      display: none;
      position: fixed;
      right: 20px;
      bottom: 20px;
    }
    #page-top a {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 10px;
      text-align: center;
      padding: 2px 8px;
      text-decoration: none;
      display: block;
      background: #fff;
      background: rgba(255,255,255,0.9);
      -webkit-border-radius: 40px;
      -webkit-border-radius: 40px;
      border-radius: 40px;
      -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.3);
      box-shadow: 0 1px 2px rgba(0,0,0,0.3);
    }
    p.copyright{
      margin:1em 1em 1em 0;
      font-size:70%;
      color:#666;
    }
  }
}
.gototop{
  z-index: 110;
  position: fixed;
  right:0;
  bottom: 0;
  width: 60px;
  height: 60px;
  background: #000;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  display: none;
  a {
    text-decoration: none;
    display: block;
    text-align: center;
    color: #fff;
    padding: 8px;
    font-size: 10px;
    line-height: 1;
    i{
      font-size: 28px;
      display: block;
      padding-top: 3px;
    }
  }
}



/* #content
------------------------------- */
.contents{
  background: #fff94a url("/_assets/img/bg.png");
  padding-bottom: 25px;
  #headline{
    background: #2E3739;
    color: #fff;
    margin-bottom: 15px;
    h1{
      a{
        color: #fff;
        &:hover{
          text-decoration: none;
        }
      }
    }
    @include mq('min', 'lg') {
      h1{
        font-size: 1.8rem;
      }
    }
    @include mq('max', 'lg') {
      h1{
        font-size: 1.5rem;
        margin: 0;
      }
    }
  }
  h2{
    line-height: 1.15;
    margin-bottom: .9rem;
    font-size: 1.75rem;
  }
  h3{
    background: $color-base;
    color: #fff;
    text-align: center;
    padding: 10px;
    font-size: 1.25rem;
    margin-bottom: .7rem;
  }
  p.note{
    color: #353E3E;
    font-size: .9rem;
  }
  .block + .block{
    margin-top: 3rem;
  }
  .viewmore{
    padding:1rem 0;
    a{
      display: inline-block;
      border:2px dashed $color-base;
      padding:9px 0 11px;
      width: 280px;
      &:hover{
        text-decoration: none;
        background: $color-base;
        color: #fff;
      }
    }
  }
  .table{
    border-bottom: 1px solid $color-base;
    td, th{
      border-top-color: $color-base;
    }
  }
  .kerning{
    font-feature-settings : "palt";
  }
}





/* top
------------------------------- */
.top{
  #feature{
    #feature-inner{
      background-image: url('/uploads/feature-5.png'), url('/uploads/feature-4.png'),url('/uploads/feature-3.png'), url('/uploads/feature-2.png'),url('/uploads/feature-1.png');
      background-repeat:no-repeat, no-repeat, repeat-x, no-repeat, repeat-x;
      background-position: top center;
      background-size: cover;
      @include mq('max', 'lg') {
        background-position: top 10%, top center, top center, top center, top center;
      }
      @include mq('min', 'xl') {
        background-size: contain;
      }
    }
  }
  .contents{
    #news{
      .slider{
        overflow: hidden;
      }
      .slide{
        margin:8px;
        cursor: pointer;
        .news-img{
          border-radius: 12px;
          background-size:cover;
          background-repeat: no-repeat;
          background-position: center center;
          width: 100%;
          padding-bottom: 75%;
        }
      }
      h3{
        background: transparent;
        color: $color-base;
        font-size: 1rem;
        font-weight: normal !important;
        text-align: left;
        padding: 0;
        margin: .4rem 0 .2rem;
        a{
          color: $color-base;
        }
      }
      div.entry-date{
        font-size: .8rem;
        color: rgba(0,0,0, .45);
      }
    }
    #movie{
      .movie{
        position: relative;
        padding: 0 0 60.2%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        iframe{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}


/* buying
------------------------------- */
.buying{
  #headline{
    background: #C62F2F;
  }
  .contents{
    .tel{
      font-size: 2.6rem;
      text-align: center;
      font-weight: bold;
      a{
        text-decoration: none;
      }
    }
    #flow, #voices, #staff{
      border-top: 1px dashed #353E3E;
      padding-top:2rem;
      margin-top:2rem;
    }
    #kanri{
      .points{
        h3{
          background: #C62F2F;
          font-size: 1.8rem;
          padding: 3px;
          strong{
            font-size: 3rem;
            vertical-align: middle;
            padding-right: .3rem;
          }
          span{
            vertical-align: middle;
          }
        }
      }
      p.lead{
        span{
          border-bottom: 1px dashed #353E3E;
          padding-bottom: .3rem;
          font-size: 1.6rem;
        }
        & + h2{
          font-size: 3rem;
        }
      }
    }
    #flow{
      .flow-down{
        font-size: 3rem;
      }
      p{
        margin-bottom: 0;
      }
      .link{
        a{
          display: block;
          max-width: 270px;
          margin: 0 auto 10px;
        }
      }
    }
    #voices{
      border-top: 1px dashed #353E3E;
      padding-top:2rem;
      margin-top:2rem;
      .voice{
        h3{
          background: transparent;
          color: $color-base;
        }
      }
    }
    #staff{
      img{
        border-radius: 50%;
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}


/* biz
------------------------------- */
.biz{
  #headline{
    background: #5B8D26;
  }
  .contents{
    .tel{
      font-size: 2.6rem;
      text-align: center;
      font-weight: bold;
      a{
        text-decoration: none;
      }
    }
    #comparison, #voices, #staff, #form{
      border-top: 1px dashed #353E3E;
      padding-top:2rem;
      margin-top:2rem;
    }
    #kanri{
      p.lead{
        span{
          border-bottom: 1px dashed #353E3E;
          padding-bottom: .3rem;
          font-size: 1.6rem;
        }
        & + h2{
          font-size: 2.8rem;
        }
      }
      .points{
        p{
          strong{
            font-size: 1.4rem;
            display: block;
          }
        }
      }
    }
    #comparison{
      em{
        color: #C62F2F;
        font-style: normal;
      }
    }
    #staff{
      .row{
        flex-wrap: wrap;
      }
      img{
        border-radius: 50%;
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}

/* about
------------------------------- */
.about-index{
  .contents{
    p {
      font-family: "Shuei Mincho M", "游明朝", YuMincho, "Hiragino Mincho ProN", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
      font-size: 18px;
      line-height: 2;
    }
    .message{
      h2{
        line-height: 1.2;
        @include mq('min', 'lg') {
          width: 1rem;
        }
      }
      img.sho {
        width: 280px;
      }
      img.photo {
        width: 275px;
      }
    }
    #mission{
      li {
        font-family: "Shuei Mincho M", "游明朝", YuMincho, "Hiragino Mincho ProN", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
      }
    }
    #philosophy p,#mission li {
      font-size: 16px;
    }
    #mission {
      ul{
        padding-left: 1.4rem;
      }
      li {
        margin-bottom: .5rem;
      }
    }
  }
}
.about-activity{
  p a{
    text-decoration: underline;
  }
}
.about-process{
  .flow-label{
    h2{
      color: #fff;
      font-size: 1.1rem;
      padding:.4rem .6rem;
      &.block-h-1{
        background: #00244F;
      }
      &.block-h-2{
        background: #003B79;
      }
      &.block-h-3{
        background: #0058A6;
      }
    }
  }
}


/* news
------------------------------- */
.news{
  .contents{
    img{
      max-width: 100% !important;
      height: auto !important;
    }
  }
}
.news-list{
  .contents{
    .asset{
      cursor: pointer;
      h2, h3{
        background: transparent;
        color: $color-base;
        font-size: 1rem !important;
        font-weight: normal !important;
        text-align: left;
        padding: 0;
        margin: .4rem 0 .2rem;
        a{
          color: $color-base;
        }
      }
      div.entry-date{
        font-size: .8rem;
        color: rgba(0,0,0, .45);
      }
    }
  }
}
.news-detail{
  .contents{
    .asset{
      .entry-date{
        font-size: .85rem;
        color: #777;
        a{
          color: #777;
          text-decoration: none;
        }
      }
    }
  }
}


/* recruit
------------------------------- */
.recruit{
  .nowrap{
    white-space: nowrap;
  }
}
